<template>
  <div>
    <div>
      <div>
        <ValidationObserver ref="formCreateCustomer">
          <form class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="name" type="text" field_name="Namn" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="company_number" type="text" field_name="Org. nummer" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseSelect v-model="alarm_central" :options="alarm_centrals" nameSelector="name" optionSelector="_id" field_name="Larmcentral" rules="required" :disabled="reseller && reseller.alarm_center != ''" :readonly="reseller && reseller.alarm_center != ''" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2" v-if="user.role == 'admin'">
              <BaseSelect v-model="seller" :options="sellers" optionSelector="_id" nameSelector="name" field_name="Säljare" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full text-sm font-bold px-2 pb-3">
              Postadress
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="address" type="text" field_name="Adress" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="zip_code" type="text" field_name="Postnummer" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="city" type="text" field_name="Stad/Ort" rules="required" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full text-sm font-bold px-2 pb-3">
              Fakturaadress
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_address" type="text" field_name="Adress " rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_zip_code" type="text" field_name="Postnummer " rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_city" type="text" field_name="Stad/Ort " rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_reference" type="text" field_name="Ref." rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="billing_invoice" type="text" field_name="E-Faktura" rules="required" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full text-sm font-bold px-2 pb-3">
              Kontaktuppgifter
            </div>

            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="contact_name" type="text" field_name="Namn" rules="required" />
            </div>

            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="phone" type="text" field_name="Telefon" rules="required" />
            </div>

            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="email" type="text" field_name="Email" rules="required|email" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseTextarea rows="3" v-model="unit_description" field_name="Kundbeskrivning" />
            </div>

            <div class="w-full mx-2 border-b border-secondary-400 my-5"></div>

            <div class="w-full px-2">
              <div class="clearfix">
                <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createCustomer()">
                  Spara
                  <BaseIcon icon="arrow-right" class="ml-2" />
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return `Skapa ny kund | SecurCloud`;
  },
  data() {
    return {
      sellers: [],
      reseller: null,
      success_message: "",
      name: "",
      company_number: "",
      address: "",
      zip_code: "",
      city: "",
      billing_address: "",
      billing_zip_code: "",
      billing_city: "",
      billing_reference: "",
      billing_invoice: "",
      contact_name: "",
      phone: "",
      email: "",
      unit_description: "",
      alarm_centrals: null,
      alarm_central: "",
      seller: "",
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    getAlarmCentrals() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central/`)
        .then((response) => {
          this.alarm_centrals = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    async getReseller() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/reseller/${this.user.reseller_id}`);
        this.reseller = response.data.data;

        // check reseller permission
        if (this.reseller.special_access.indexOf("create_customer") == -1) this.$router.push(`/kunder/reseller/${this.user.reseller_id}`);

        if (this.reseller.alarm_center) this.alarm_central = this.reseller.alarm_center;
      } catch (error) {
        this.handleError(error);
      }
    },

    getSellersResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    createCustomer() {
      this.$refs.formCreateCustomer.validate().then((success) => {
        if (!success) {
          return;
        }

        let data = {
          name: this.name,
          company_number: this.company_number,
          address: this.address,
          zip_code: this.zip_code,
          city: this.city,
          billing_address: this.billing_address,
          billing_zip_code: this.billing_zip_code,
          billing_city: this.billing_city,
          billing_reference: this.billing_reference,
          billing_invoice: this.billing_invoice,
          contact_name: this.contact_name,
          phone: this.phone,
          email: this.email,
          unit_description: this.unit_description,
          alarm_central: this.alarm_central,
          seller: this.seller,
        };

        if (this.user.role == "reseller") data.seller = this.user.reseller_id;

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/customer/`, data)
          .then(() => {
            // eslint-disable-line
            this.success_message = "Nya kunden skapad!";
            if (this.user.role == "reseller") this.$router.push(`/kunder/reseller/${this.user.reseller_id}`);
            else this.$router.push("/admin/kunder");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },
  },

  created() {
    this.getAlarmCentrals();

    if (this.user.role == "reseller") this.getReseller();
    if (this.user.role == "admin") this.getSellersResellers();

    this.setPageTitle(`Skapa ny kund`, "create_customer_admin");
  },
};
</script>
